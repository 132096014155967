/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-02 09:59:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import { getCookies, setCookies, removeCookies } from '@/utils/utils'


Vue.use(Vuex);
const store = new Vuex.Store({
    actions:{
        pushRouterPath({commit},params){
            commit('PUSH_TABS_PATH',params);
        },
        removeRouterPath({commit},params){
            commit('REMOVE_TABS',params);
        },
        setCookiesStorage({commit},params){
            setCookies('x-token',params.accessToken);
            setCookies('sysId',params.sysId);
            setCookies('sysName',params.sysName);
            setCookies('sysPhone',params.sysPhone);
            setCookies('roleId',params.roleId);
            setCookies('organizationId',params.organizationId);
            setCookies('organizationName',params.organizationName);
            setCookies('sysMenus',params.sysMenus);
            console.log('sysMenus');
            console.log(JSON.stringify(params.sysMenus).length);
            console.log(getCookies('sysMenus'));
            console.log(getCookies('roleId'));
        },
        removeCookiesStorage({commit},){
            removeCookies('x-token')
            removeCookies('sysId')
            removeCookies('sysName')
            removeCookies('sysPhone')
            removeCookies('roleId')
            removeCookies('organizationId')
            removeCookies('organizationName')
            removeCookies('sysMenus')
            removeCookies('TabsMenuValue')
            removeCookies('TabsMenuArr')
            commit('SET_TABS');
            router.push({ path: '/login' })
        }
    },
    getters:{
        
    },
    state:{
        TabsMenuArr: JSON.parse(getCookies('TabsMenuArr')) || [],
        TabsMenuValue: getCookies('TabsMenuValue') || '',

    },
    mutations:{
        SET_TABS(state, payload) {
            state.TabsMenuArr = [];
            state.TabsMenuValue = '';
        },
        PUSH_TABS_PATH:(state, params) => {
            state.TabsMenuValue = isQueryExist(params);;
            state.TabsMenuArr = addOREdit(state.TabsMenuArr,params,state.TabsMenuValue);
            setCookies('TabsMenuArr',JSON.stringify(state.TabsMenuArr));
            setCookies('TabsMenuValue',state.TabsMenuValue);
            
        },
        REMOVE_TABS:(state,params)=>{
            
            let targetName = params.name;
            let tabs = JSON.parse(JSON.stringify(state.TabsMenuArr));
            let activeName = JSON.parse(JSON.stringify(state.TabsMenuValue));
            if (activeName.indexOf(targetName) != -1) {
                tabs.forEach((tab, index) => {
                    
                    if (tab.name.indexOf(targetName) != -1) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) {
                            activeName = nextTab.name;
                            router.push({path:activeName})
                        }
                    }
                });
            }
            
            state.TabsMenuValue = activeName;
            state.TabsMenuArr = tabs.filter(tab => tab.name.indexOf(targetName) == -1);
            setCookies('TabsMenuArr',JSON.stringify(state.TabsMenuArr));
            setCookies('TabsMenuValue',state.TabsMenuValue);
            
        },
    },
})
  
export default store;

function isQueryExist(params){
    let path = params.path;
    let queryArr = Object.keys(params.query);
    let queryObj = params.query;
    

    // 判断跳转路由是否包含query参,有则加在path中
    if(queryArr.length){
        path = params.path+'?';
        let i = 0;
        // 拼接path参数
        for(let key in queryObj){
            path += queryArr[i]+'='+queryObj[key];
            i++;
            // 最后参数则不加 '&'
            if(i < queryArr.length){
                path += '&';
            }       
        }
    }
    return path;
}
function addOREdit(arr,params,path){
    let TabsMenuArr = JSON.parse(JSON.stringify(arr));
    // index == -1 为新tab，添加至tab数组中，不为-1为已有tab，重新设置tab的path路径（更新参数）
    let index = JSON.stringify(TabsMenuArr).indexOf(params.path);
    
    if( index == -1){
        TabsMenuArr.push({
            title: params.name,
            name: path,    
        });
    }else{
        // 检索tab数组中匹配的索引并修改
        TabsMenuArr.forEach((item,idx)=>{
            if(item.name.indexOf(params.path) != -1){
                index = idx;
            }
        })
        TabsMenuArr[index].name = path;
    }
    return TabsMenuArr;
}