<!--
 * @Author: your name
 * @Date: 2021-01-27 11:06:28
 * @LastEditTime: 2021-02-02 15:04:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \weilaiyouni_web\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style>
.btn-hover {
  margin-right: 8px;
}
.btn-hover:hover {
  cursor: pointer;
}
html,
body,
#app {
  height: 100%;
  margin: 0;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.3);
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px !important;
}
.el-dialog {
  text-align: center;
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
.right-button {
  width: 100px !important;
  margin: 6px 0 !important;
}
.top-btn {
  margin: 10px;
}
</style>
