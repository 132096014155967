import Vue from "vue";
import Router from "vue-router";
import { getCookies } from "@/utils/utils";
Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/index",
    component: () => import("@/views/index/index"),
    hidden: true,
    children: [
      {
        path: "/",
        redirect: "/default",
      },
      {
        path: "/default",
        component: () => import("@/views/index/default.vue"),
        name: "首页",
        // component:() => import('@/views/service/outlets/index.vue'),
        hidden: true,
      },
      {
        path: "/overview",
        component: () => import("@/views/index/overview.vue"),
        name: "overview",
        // component:() => import('@/views/service/outlets/index.vue'),
        hidden: true,
      },
      {
        path: "/finance",
        component: () => import("@/views/index/finance.vue"),
        name: "finance",
        // component:() => import('@/views/service/outlets/index.vue'),
        hidden: true,
      },

      {
        path: "/login",
        name: "登录",
        component: () => import("@/views/login/index.vue"),
        hidden: true,
      },

      {
        path: "/integrals/redeem-code/index",
        name: "兑换码",
        component: () => import("@/views/integrals/index.vue"),
        hidden: true,
        meta: {
          title: "积分核销码",
          batchType: 4,
          exchangeType: 0,
        },
      },
      {
        path: "/integrals/real-name/index",
        name: "实名发放列表",
        component: () => import("@/views/integrals/index.vue"),
        hidden: true,
        meta: {
          title: "积分前三后四",
          batchType: 5,
          exchangeType: 1,
        },
      },
      {
        path: "/integrals/job-number/index",
        name: "jobNumber",
        component: () => import("@/views/integrals/index.vue"),
        hidden: true,
        meta: {
          title: "积分工号发放",
          batchType: 11,
          exchangeType: 3,
        },
      },
      {
        path: "/integrals/direct/index",
        name: "直接发放列表",
        component: () => import("@/views/integrals/index.vue"),
        hidden: true,
        meta: {
          title: "积分直接发放",
          batchType: 6,
          exchangeType: 2,
        },
      },
      {
        path: "/integrals/grant",
        name: "integralsGrant",
        component: () => import("@/views/integrals/grant.vue"),
        hidden: true,
      },
      {
        path: "/integrals/importQuery",
        name: "importQuery",
        component: () => import("@/views/integrals/importQuery.vue"),
        hidden: true,
      },

      {
        path: "/codes/coupons/index",
        name: "折扣券列表",
        component: () => import("@/views/phaseOil/coupons/index.vue"),
        hidden: true,
      },
      {
        path: "/codes/coupons-packge/index",
        name: "期油套餐发放",
        component: () => import("@/views/phaseOil/coupons-packge/index.vue"),
        hidden: true,
      },
      {
        path: "/codes/eSubstituteDriving/index",
        name: "e代驾",
        component: () => import("@/views/codes/eSubstituteDriving.vue"),
        // hidden: true,
      },
      {
        path: "/codes/nowCode/index",
        name: "nowCode",
        component: () => import("@/views/codes/index.vue"),
        hidden: true,
        meta: {
          batchType: 0,
        },
      },
      {
        path: "/codes/realName/index",
        name: "realName",
        component: () => import("@/views/codes/index.vue"),
        hidden: true,
        meta: {
          batchType: 1,
        },
      },
      {
        path: "/codes/recharge/index",
        name: "recharge",
        component: () => import("@/views/codes/index.vue"),
        hidden: true,
        meta: {
          batchType: 2,
        },
      },
      {
        path: "/codes/receive/index",
        name: "receive",
        component: () => import("@/views/codes/index.vue"),
        hidden: true,
        meta: {
          batchType: 12,
        },
      },
      {
        path: "/codes/grant",
        name: "codesGrant",
        component: () => import("@/views/codes/grant.vue"),
        hidden: true,
        meta: {
          type: 1,
          exchangeType: "1,2,3",
        },
      },
      {
        path: "/issueList/index",
        name: "发放管理",
        component: () => import("@/views/issueList/index.vue"),
        hidden: true,
      },
      {
        path: "/issueList/exportRecord",
        name: "导出记录",
        component: () => import("@/views/issueList/exportRecord.vue"),
        hidden: true,
      },
      {
        path: "/issueList/czRecord",
        name: "创展记录",
        component: () => import("@/views/issueList/czRecord.vue"),
        hidden: true,
      },
      {
        path: "/issueList/customerList",
        name: "发放名单",
        component: () => import("@/views/issueList/customerList.vue"),
        hidden: true,
      },
      {
        path: "/issueList/record",
        name: "issueListRecord",
        component: () => import("@/views/issueList/record.vue"),
        hidden: true,
      },
      {
        path: "/codes/approval/index",
        name: "approval",
        component: () => import("@/views/approval/index.vue"),
        hidden: true,
      },
      {
        path: "/setting/coupons-packge/index",
        name: "设置折扣券套餐",
        component: () => import("@/views/setting/coupons-packge/index.vue"),
        hidden: true,
      },
      {
        path: "/rule/index",
        name: "规则配置",
        component: () => import("@/views/setting/rule/index.vue"),
        hidden: true,
      },
      {
        path: "/integral-rule/index",
        name: "积分规则配置",
        component: () => import("@/views/setting/integral-rule/index.vue"),
        hidden: true,
      },
      {
        path: "/setting/menu/index",
        name: "权限管理",
        component: () => import("@/views/setting/menu/index.vue"),
        hidden: true,
      },
      {
        path: "/account/index",
        name: "账号管理",
        component: () => import("@/views/setting/account/index.vue"),
        hidden: true,
      },
      {
        path: "/organization/index",
        name: "机构管理",
        component: () => import("@/views/setting/organization/index.vue"),
        hidden: true,
      },
      {
        path: "/clearing/index",
        name: "账号管理",
        component: () => import("@/views/clearing/index.vue"),
        hidden: true,
      },
      {
        path: "/clearing/hnIndex",
        name: "hnIndex",
        component: () => import("@/views/clearing/hnIndex.vue"),
        hidden: true,
      },

      {
        path: "/cardcoupon/oil",
        name: "期油券配置",
        component: () => import("@/views/cardCoupon/coupons.vue"),
        hidden: true,
      },
      {
        path: "/cardcoupon/wash",
        name: "洗保喷券配置",
        component: () => import("@/views/cardCoupon/coupons.vue"),
        hidden: true,
      },
      {
        path: "/giftConfiguration/index",
        name: "giftConfiguration",
        component: () => import("@/views/cardCoupon/giftConfiguration.vue"),
        hidden: true,
      },

      {
        path: "/customer/index",
        name: "customerIndex",
        component: () => import("@/views/customer/index.vue"),
        hidden: true,
        meta: {
          hidden: 0,
        },
      },
      {
        path: "/customer/search",
        name: "customerIndex",
        component: () => import("@/views/customer/index.vue"),
        hidden: true,
        meta: {
          hidden: 1,
        },
      },
      {
        path: "/customer/info",
        name: "customerInfo",
        component: () => import("@/views/customer/info.vue"),
        hidden: true,
      },
      {
        path: "/customer/detail",
        name: "customerDetail",
        component: () => import("@/views/customer/detail.vue"),
        hidden: true,
      },

      {
        path: "/customer/consumptionQuery",
        name: "consumptionQuery",
        component: () => import("@/views/customer/consumptionQuery.vue"),
        hidden: true,
      },
      {
        path: "/customer/active",
        name: "customerActive",
        component: () => import("@/views/customer/active.vue"),
        hidden: true,
      },
      {
        path: "/hn/oilCoupon/direct",
        name: "hnDirect",
        component: () => import("@/views/hn-codes/index.vue"),
        hidden: true,
        meta: {
          type: 1,
          batchType: 8,
          exchangeType: 3,
        },
      },

      {
        path: "/hn/oilCoupon/verification",
        name: "hnVerification",
        component: () => import("@/views/hn-codes/index.vue"),
        hidden: true,
        meta: {
          type: 2,
          batchType: 9,
          exchangeType: 1,
        },
      },
      {
        path: "/hn/oilCoupon/threeFour",
        name: "hnThreeFour",
        component: () => import("@/views/hn-codes/index.vue"),
        hidden: true,
        meta: {
          type: 3,
          batchType: 10,
          exchangeType: 2,
        },
      },
      {
        path: "/hn/oilCoupon/grant",
        name: "hnGrant",
        component: () => import("@/views/hn-codes/grant.vue"),
        hidden: true,
        meta: {
          type: 1,
          exchangeType: "1,2,3",
        },
      },
      {
        path: "/appSettings/products",
        name: "products",
        component: () => import("@/views/appSetting/products/index.vue"),
        hidden: true,
      },
      {
        path: "/appSettings/menu",
        name: "menu",
        component: () => import("@/views/appSetting/menu/index.vue"),
        hidden: true,
      },
      {
        path: "/appSettings/version",
        name: "version",
        component: () => import("@/views/appSetting/version/index.vue"),
        hidden: true,
      },
      {
        path: "/appSettings/setPage",
        name: "setPage",
        component: () => import("@/views/appSetting/setPage/index.vue"),
        hidden: true,
      },
      {
        path: "/tGood/getGoosPage",
        name: "goods",
        component: () => import("@/views/goods/goods.vue"),
        hidden: true,
      },
      {
        path: "/tGood/getShoppingOrderPage",
        name: "oederList",
        component: () => import("@/views/goods/oederList.vue"),
        hidden: true,
      },
    ],
  },
];
const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
router.beforeEach((to, from, next) => {
  if (to.path.indexOf("login") == -1) {
    router.app.$options.store.dispatch("pushRouterPath", to);
  }
  if (to.path === "/login") {
    //如果是登录页，则跳过验证
    next(); //必不可少
    return; //以下的代码不执行
  }

  if (!getCookies("x-token") && router.path !== "/") {
    next({
      path: "/login",
    });
  } else next();
});
export default router;
