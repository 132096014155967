/*
 * @Author: ken
 * @Date: 2020-12-11 10:19:31
 * @LastEditTime: 2021-02-02 11:31:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\main.js
 */
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import Element from 'element-ui'
import filters from "./filters/index.js";
console.log(filters);
Object.keys(filters).map((v) => {
  Vue.filter(v, filters[v]);
});
//import VueAMap from 'vue-amap'

import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css';

Vue.prototype.$validate = function (formName){
  let a = true
  this.$refs[formName].validate((valid)=>{
    if(!valid){
      a=false
      this.$message.error({
          title: '错误',
          message: '请检查完善输入项并重试',
          duration: 1500
      });
      return
    }
  })
  return a
}
Vue.use(Element);
// Vue.use(VueAMap)
// // 高德地图Api key码所属账号为 15361412452
// VueAMap.initAMapApiLoader({
//   key: '383ce0231e844fe085cec644cbeb5420',
//   plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
//   v: '1.4.4',
//   uiVersion: '1.0'
// })
Vue.config.productionTip = false

Vue.directive('debounce', {//按钮防抖指令
  inserted: function (el, binding) {
    let timer;
    let i=0
    el.addEventListener("click", () => {
      if(i++>=5){
        Element.Message.warning('操作频繁，5秒后重试')
        i=0
      }
      if (timer) {
        clearTimeout(timer);
      }
      let callNow = !timer
      timer = setTimeout(() => {
        timer=null
        i=0
      }, 5000)
      if (callNow) binding.value();
    })
  }
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
